<template>
  <v-card-title primary-title>
    <div class="cr-base-form-display-info">
      <div class="headline">
        {{ row.firstName }}
        {{ row.lastName }}
      </div>
      <div>
        Email:
        <a :id="`${id}-quick-view-href-email-to`" :href="`mailto:${row.email}`">
          {{ row.email }}
        </a>
      </div>
      <div>
        Group:
        {{ row.groupName }}
      </div>
      <div>
        Company:
        {{ row.companyName }}
      </div>
    </div>
  </v-card-title>
</template>

<script>
export default {
  props: {
    row: {
      type: Object,
      default: () => ({}),
      required: true,
      firstName: {
        type: String,
        default: '',
      },
      lastName: {
        type: String,
        default: '',
      },
      email: {
        type: String,
        default: '',
      },
      groupName: {
        type: String,
        default: '',
      },
      companyName: {
        type: String,
        default: '',
      },
    },
    id: {
      type: String,
      default: null,
    },
  },
  mounted() {},
}
</script>
