<template>
  <v-container>
    <v-layout row wrap>
      <v-flex>
        <v-layout row wrap>
          <template v-if="row.action === 'delete_user_detail'">
            <BaseDeletion
              :close="closeCard"
              :collection-name="'user'"
              :row="row"
              :special-identifier="'userId'"
            />
          </template>
          <template v-if="row.action === 'detail'">
            <UsersQuickView :id="`users-view-detail`" :row="row" />
          </template>
        </v-layout>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import BaseDeletion from '@/components/BaseDeletion.vue'
import UsersQuickView from '@/components/UsersQuickView.vue'

export default {
  components: {
    BaseDeletion,
    UsersQuickView,
  },
  props: {
    row: {
      type: Object,
      default: () => ({}),
    },
    props: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      loading: false,
      expanded: this.props.expanded,
    }
  },
  methods: {
    closeCard() {
      this.props.expanded = false
    },
  },
}
</script>
