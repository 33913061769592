<template>
  <BaseTableWrapper :table-init="tableInit">
    <h1 class="page-header">Users</h1>
  </BaseTableWrapper>
</template>

<script>
import { DateTime } from 'luxon'
import BaseTableWrapper from '@/components/BaseTableWrapper.vue'
import BaseActionsRequiresWrapper from '@/components/BaseActionsRequiresWrapper.vue'
import UserViewDetail from '@/components/UserViewDetail.vue'
import actionsWrapper from '@/utils/BaseActionsWrapper'
import { deepClone } from '@/utils/deepClone'
import GenericActionableColumn from '@/components/GenericActionableColumn.vue'
import { authComputed } from '@/state/helpers'
import { datePredefined } from '@/utils/predefined'
import companies from '@/services/companies'

const wrappedBaseActions = actionsWrapper(BaseActionsRequiresWrapper, 'user')

const formatDate = (item, row) => {
  let date = DateTime.fromISO(item, {
    zone: row['timeZone'] || 'local',
  })

  if (!date.isValid) {
    return ''
  }

  return date.toFormat('MM/dd/yyyy t ZZZZ')
}

const columns = [
  {
    _t_id: 'db156d27',
    prop: '/',
    text: 'Actions',
    component: wrappedBaseActions,
    sort: false,
    filter: false,
    detail: false,
    type: 'actions',
  },
  {
    _t_id: '0ffb911f',
    prop: ['firstName', 'lastName'],
    text: 'Name',
    sort: true,
    filter: true,
    type: 'text',
    filterType: 'contains',
    sortProp: 'lastName',
    defaultSort: true,
  },
  {
    _t_id: 'a2418306',
    prop: 'email',
    text: 'Email',
    sort: true,
    filter: true,
    component: GenericActionableColumn,
    displayType: 'clickable-email',
    type: 'text',
    filterType: 'contains',
    sortProp: 'email',
  },
  {
    _t_id: '713c1524',
    prop: 'groupName',
    text: 'Group',
    sort: true,
    filter: true,
    type: 'text',
    filterType: 'contains',
    sortProp: 'groupName',
  },
  {
    _t_id: '36a13317',
    prop: 'createdOn',
    text: 'Created On',
    computedText: formatDate,
    sort: true,
    filter: true,
    type: 'date',
    method: 'and',
    childMethod: 'and',
    predefined: deepClone(datePredefined),
    filterType: 'eq',
  },
  {
    _t_id: '24ea0333',
    prop: 'updatedOn',
    text: 'Updated On',
    computedText: formatDate,
    sort: true,
    filter: true,
    type: 'date',
    method: 'and',
    childMethod: 'and',
    predefined: deepClone(datePredefined),
    filterType: 'eq',
  },
  {
    _t_id: '37ee93f51',
    prop: 'lastLogin',
    text: 'Last Login',
    computedText: formatDate,
    sort: true,
    filter: true,
    type: 'date',
    method: 'and',
    childMethod: 'and',
    predefined: deepClone(datePredefined),
    filterType: 'eq',
  },
  {
    _t_id: '4154a2da',
    prop: 'companyName',
    text: 'Company',
    sort: true,
    filter: true,
    component: GenericActionableColumn,
    displayType: 'clickable-company',
    type: 'text',
    filterType: 'contains',
    sortProp: 'companyName',
  },
]

export default {
  components: {
    BaseTableWrapper,
  },
  metaInfo() {
    return {
      title: 'Users',
    }
  },
  data() {
    return {
      isAdmin: false,
      tableInit: {
        detail: UserViewDetail,
        detailKeyId: 'userId',
        tableId: 'users_tv_view',
        enableStatusFilterToggle: false,
        enableSavedViews: false,
        addNewEnabled: true,
        addNewHandler: () => this.$router.push({ name: 'users.add' }),
        currentPage: 1,
        perPage: 10,
        isDetailed: true,
        columns,
        collection: 'Users',
      },
    }
  },
  computed: {
    ...authComputed,
  },
  async mounted() {
    const companyList = await companies.getCompanies({}).catch(() => null)
    if (!companyList) {
      this.tableInit.columns[4].displayType = 'normal'
    }

    if (this.isSuperAdmin) {
      this.tableInit.columns.push({
        _t_id: '30e344778',
        prop: 'applicationName',
        text: 'Application Name',
        sort: true,
        filter: false,
        type: 'text',
        sortProp: 'applicationName',
      })
    }
  },
}
</script>
